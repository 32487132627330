import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/Sighthound/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Sighthound for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Sighthound.png",
  "social": "/images/Search/P_SearchThumb_Sighthound.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Sighthound_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Webcam Motion Detector' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Sighthound for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Sighthound.png' twitter='/images/Search/P_SearchThumb_Sighthound.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Webcam_Motion_Detector/' locationFR='/fr/Software/Windows/Webcam_Motion_Detector/' crumbLabel="Webcam Motion Detector" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h2 {...{
      "id": "webcam-motion-detector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#webcam-motion-detector",
        "aria-label": "webcam motion detector permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Webcam Motion Detector`}</h2>
    <p>{`ZM Surveillance System is a video surveillance and motion capture software. Some key features are: unlimited cameras, motion detection, SMS and MMS and Email alerts, play sound file (useful on motion detection), adjustable sensitivity for each zone, record on detection, save video into archive or file with automatically or manually selected compression, record on schedule, registration of all events continuously by intervals or on motion detected, password protection, adding a text, time stamps, watermarks to your video. For each video frame, if motion is detected, the Alarm event occurs, that returns a global motion ratio, depending of the number of cells in which motion has been detected, and the level of motion in each cell.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in the `}<a href="http://www.zebra-media.com/webcammotiondetector.html" target="_blank" rel="noopener noreferrer">{`Webcam Motion Detector`}</a>{` we recommend you to download the latest version from `}<a href="http://www.zebra-media.com/webcammotiondetector.html" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`Click on the Add Camera button to open the camera wizard. The video path to your camera consist of the local IP address and the login credentials. The video path would be:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`VGA Video Path: http://192.168.1.237:80?user=admin&pwd=`}</code></pre></div>
    <p>{`for a camera with the default login user = admin, password = none, the local IP address 192.168.1.237 and the HTTP port 80. Please adjust the path if you changed the default login or the default web port accordingly. For an HD camera with the default login user = admin and password = instar, the local IP address 192.168.1.175 and the HTTP port 85:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`HD Video Path: http://192.168.1.237:80/tmpfs/snap.jpg`}</code></pre></div>
    <p>{`It is possible to use an external (very expensive) plugin to connect to the camera´s RTSP video stream instead of the JPG refresh mode. Please refer to the instructions given on the official website if you need to use the HD video stream.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5db6166eb793a0de52a26c6bd91db36c/00d43/Webcam-Motion-Detector_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlUlEQVQoz8XS2U4bMRQGYL80SAVaiYoSmpmQThKFTGJPZsk2axbGa/DYTiq1N32KvkgVBC0oQajtBdZ3Ycs+0pH/A7zbbyjlznBpIdLwyBVc1eDSQvjay5sothFuoPLSXVz25w2fNQJ6/egzJID++Dkh39tBCbMKpVV3jPsz2hsxOF31o7wbEHfMuhFpBbedyfpLyG2f1RC5gOTcLUEd4VpveerMT5zixCneNYv3zfzMKZyQd0bC8Uk7Yp0Rb4WsGVB7SOoI1z1sD4nlEeDHdFawKKFhSsKURhmLUhqmNF6IAt8VpczLu+mcTQt+b7eZzXm2Wo9zAaiovm7NZqO3z22MMvrBxuinjNk9IFyCkkqtVFXtUU/s3WqtSioBZtJo9bfLaIXZa8X6n4tlVUlZvUXxf7X9+oc9RPU7EnUoqj27qJgEhMut0Vr/GQmjtXl2PGBr1G5IbiYUJjzIRJivBzGHCUcxG8QcpWKYvcjPhTvloObmx1byobX41FseW9nFzepjOzuqZzbCXrrux2JwCExEd8x/Aabfp37s8sWAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5db6166eb793a0de52a26c6bd91db36c/e4706/Webcam-Motion-Detector_01.avif 230w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/d1af7/Webcam-Motion-Detector_01.avif 460w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/7f308/Webcam-Motion-Detector_01.avif 920w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/445fc/Webcam-Motion-Detector_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5db6166eb793a0de52a26c6bd91db36c/a0b58/Webcam-Motion-Detector_01.webp 230w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/bc10c/Webcam-Motion-Detector_01.webp 460w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/966d8/Webcam-Motion-Detector_01.webp 920w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/a5d4d/Webcam-Motion-Detector_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5db6166eb793a0de52a26c6bd91db36c/81c8e/Webcam-Motion-Detector_01.png 230w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/08a84/Webcam-Motion-Detector_01.png 460w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/c0255/Webcam-Motion-Detector_01.png 920w", "/en/static/5db6166eb793a0de52a26c6bd91db36c/00d43/Webcam-Motion-Detector_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5db6166eb793a0de52a26c6bd91db36c/c0255/Webcam-Motion-Detector_01.png",
              "alt": "Webcam Motion Detector",
              "title": "Webcam Motion Detector",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Add Camera button to get started.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9632ff64473a84f33505d3e76757c16c/4ad3a/Webcam-Motion-Detector_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVQozyXQ708bdQAG8O+3vd5dr3e9u7aUtWzAAEdmlhhZFrOYLDFRJwiMbQGk0FJQX/jGN9MZ35glMiDZC2N0jB/SAr3+uGuv7V3bu7Z3vbYcrpAxRLOpMfGNZv+HKT75vHlePG8esLTGP3hSvPeddP+H4lc/lu4/Vr5eUx7tVVej6mpEWY2oy9HycrSyvKOt7Ooru9rDaPVhpLL0k7K0IYNtvrLG5TaS8pZQ2EwW12Pqeqy8Ga/spKubieI6J0d4JZHTOVHbTinrnMzldKFQEwpGulQH2UpLaT5Xm8cV86T129/Hf7w6evnv4Yt/jl62Hf/5Smu92OSkSKqYyFWSks4XDFGp/w9UW7/ntFZcMlKFRrZsCsVGQtITeZ2XjXSpKVZ+5iTjSUzeShR2hBKXqyYlLV3QhTMg3/xrSzC+jxYec+qeWN3LqBFe2U2ribwez+lJ2eCLDbF8kFHNjGpupZu8+lRtHJXqbWAjlt1OyrsZNZqSA3PhqZng4sIns8H5QDAcCi8GQwvh8OJcaGEmGJ6Zm58OhGbnwlOB0HRgfnImBPTm4cHh86fPTuN8jiQIAIAHs5IWYAHt2CDArNAGoRW2qwVCEkUgAGcNgGfHJ7+cnPx6eiqIeZphbQiCY7gdwztcrp7+fpZl3DTFUCRNkjRJuFknRZKEnbLjGEVfAlWtVqvV9vf3+Uze5/WQqNVHon1ux5UuZsBH93ayV3uZy10uv9t5odPV7XG6aAfjZQjc5iAuAE036oZhmqaYlS76Ohgc8dP2c07cTeKMA6cIgnI4KNJJUTRNs3bCSTAOto/BEBRDrUA/Gx+YZjqTpygnAqEbQQBEEBtqx3Enw/r9vqtXBt98fdA/OOT2+FjfOe9rXRDarAgKJNWQy4ai73OCdHGg3+tifHYMJ13n/R2XzrsHLw9cvzY0PfrOezfe/ii02NPd2zfQ39vTjdowO4YDUWmISl3SDnZSsqezs/0htECUAhZoQyDFkNevDX3+aXAhMH3jrSEvTb7R18NShM0C/awDxEQ1kVV5qbodFz8Y/nDk5vDIyNjN4bHh0fGJ27fvTk7e++LLb1ceffNgZf7jz959f3z2ztTY+MTYxJ3RW3f/A4msVbYAJo9OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9632ff64473a84f33505d3e76757c16c/e4706/Webcam-Motion-Detector_02.avif 230w", "/en/static/9632ff64473a84f33505d3e76757c16c/d1af7/Webcam-Motion-Detector_02.avif 460w", "/en/static/9632ff64473a84f33505d3e76757c16c/7f308/Webcam-Motion-Detector_02.avif 920w", "/en/static/9632ff64473a84f33505d3e76757c16c/b019a/Webcam-Motion-Detector_02.avif 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9632ff64473a84f33505d3e76757c16c/a0b58/Webcam-Motion-Detector_02.webp 230w", "/en/static/9632ff64473a84f33505d3e76757c16c/bc10c/Webcam-Motion-Detector_02.webp 460w", "/en/static/9632ff64473a84f33505d3e76757c16c/966d8/Webcam-Motion-Detector_02.webp 920w", "/en/static/9632ff64473a84f33505d3e76757c16c/b2d4b/Webcam-Motion-Detector_02.webp 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9632ff64473a84f33505d3e76757c16c/81c8e/Webcam-Motion-Detector_02.png 230w", "/en/static/9632ff64473a84f33505d3e76757c16c/08a84/Webcam-Motion-Detector_02.png 460w", "/en/static/9632ff64473a84f33505d3e76757c16c/c0255/Webcam-Motion-Detector_02.png 920w", "/en/static/9632ff64473a84f33505d3e76757c16c/4ad3a/Webcam-Motion-Detector_02.png 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9632ff64473a84f33505d3e76757c16c/c0255/Webcam-Motion-Detector_02.png",
              "alt": "Webcam Motion Detector",
              "title": "Webcam Motion Detector",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your camera´s video path and click to Start the preview.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1c308373a6e6a9991b7709082f856f33/4cdf7/Webcam-Motion-Detector_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEFklEQVQ4y1WLeUxbdQCAf3OAgQGDcbgBRgYuJMYp04g7nQMxMjwyFyAaHQJBHJFwQ4ZcyhmGJMI4RWADEYTB6LjWMVJoC2NA6QktBQRsH3197Xvta/t692fmP8bky5d8f3yghb4+uowNrZAV05rSKV35lK5snCh/qCx9gJTel5fd3y99gFRMqirouv+YwstpiuLBHTDDlbGECEOooq8ic5yDGQE6I8SYInJBrFgRbzHXyQUxObOmmuTImRI9W2pgbVIsKcWUUvMSCkyvYTN8NUNEzq/jiyJiUaxd2TM+FRtWt8lliXJxg1za1C9vUWyxhiU1MSSmxyJqnKcf4ZDDq1owK9I94mr7WaqBBayfrf6dgQ6zlEMslL1lfrZrZW8alraNnD3Ls23T020TW2pkbhrmJIb5TQNLSoEuuqS4R5DVys1q42a3rWW183PaedmtnKIeUeWgpHJAUty7XtglzO8U5ncK8n7l5Xbwcjv4t7pFpb1iIENVKEZgOEloDaiKIPQmlUaPKDA5qpYdYHsH+C6iUmoMhM5E6M0KtQ5VkzhpxLRGBDcCIWJYJ+CaGq5hkKuAAgxuaOGGDoq1UIBClc68i5u3NJCPQu7fViFql2igGIe7Krvd7gDVd/qyS+qTChq+zKpNL2oorm0prG6+VdNaVNuaV3lniS+tbOrLq+38oXmsvG2seWjut1FGTdtga9+ExeYA7557HwDg7+YU4Ovp7+Hi7fqC62Hg4XzIGQDnFz1HHs5GR1268M7rqclf5+TkFhTkJyd+cTrslYT4OJsDgujojzwPg7ATXq8G+Iae8DkV6Bfg5xv4ku+xQC8fn6DhsScfXHr7w8tvxSVEF/6YkZGddP2TyDdC/OI//9gGIbgcFeMJgLe7h4+XZ3Bw8HsR4WcvXgkMCfM/edzVzWNodOLMqdCTXk4FmVeXRIP9vSV1N2OjQl0TYqOsDgiuXktwc3Hy9gsIOH7s4oWIxqqS9NSUs+FvvhYaEhT48sTk48TI003JEfTOMjZ9hNbxE6Mp/V5SeH3ap2Y7BBHnrwAAXFycj7i5RZ4/11JXk5OWEuZ7JMj90NGj7uPjk90ZURMlMbT61LGGmxN1Nx7VxE9lnunNv26yQVBZ13Htq+9jPkuKjUuN/yYzI78mt7A6OfG7lG/Tb6RlMZfW7jZV9f5Sda/x9kDL7T/bf/6jua6rMrenseH5bNCZcQruYBDR2FCt9S/ULFdbEMJmgtAKod5kUegNEsTE3zPuoNSWgpLITVuIcR+zQAjBPmEjKLve7NAYHTjlUBvsKsqBU/YDjUWGm+WEVaGxKXU2TG9T6uxK0obpnidK2vbVFjDBkd+dldOW8WmuumdWQeNoaQtI95MDuoAUHFh5MgtPbuHK/gfvX/Pl1n8AfN+bmu4d0n0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c308373a6e6a9991b7709082f856f33/e4706/Webcam-Motion-Detector_08.avif 230w", "/en/static/1c308373a6e6a9991b7709082f856f33/d1af7/Webcam-Motion-Detector_08.avif 460w", "/en/static/1c308373a6e6a9991b7709082f856f33/7f308/Webcam-Motion-Detector_08.avif 920w", "/en/static/1c308373a6e6a9991b7709082f856f33/5075a/Webcam-Motion-Detector_08.avif 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1c308373a6e6a9991b7709082f856f33/a0b58/Webcam-Motion-Detector_08.webp 230w", "/en/static/1c308373a6e6a9991b7709082f856f33/bc10c/Webcam-Motion-Detector_08.webp 460w", "/en/static/1c308373a6e6a9991b7709082f856f33/966d8/Webcam-Motion-Detector_08.webp 920w", "/en/static/1c308373a6e6a9991b7709082f856f33/157c9/Webcam-Motion-Detector_08.webp 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1c308373a6e6a9991b7709082f856f33/81c8e/Webcam-Motion-Detector_08.png 230w", "/en/static/1c308373a6e6a9991b7709082f856f33/08a84/Webcam-Motion-Detector_08.png 460w", "/en/static/1c308373a6e6a9991b7709082f856f33/c0255/Webcam-Motion-Detector_08.png 920w", "/en/static/1c308373a6e6a9991b7709082f856f33/4cdf7/Webcam-Motion-Detector_08.png 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1c308373a6e6a9991b7709082f856f33/c0255/Webcam-Motion-Detector_08.png",
              "alt": "Webcam Motion Detector",
              "title": "Webcam Motion Detector",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save the settings to start using the software.`}</p>
    <h3 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h3>
    <p>{`The ZM Surveillance System allows you to draw a motion detection grid over the cameras live view. Adjust the cell size as well as the cell sensitivity to your needs. The higher the number inside a cell, the higher the sensitivity will be in this area. You can further except reds, greens and blues from the motion detection, or just use a monochrome image to further reduce false alerts.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a288e64739fc8bed672990123b6fa912/4ad3a/Webcam-Motion-Detector_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADTElEQVQozzWQX2gbdQDHf5ekyeVyuctdkmtz7bradQl2pUbn2BRhgmxgH4b4MHwSwT/t8qfNpWnnHP5BQXzdw14UdGIRFBn4MAQ3WtruYc5ukGS0Nuk1/5p/vVz+Xf7scrn7SR1++bx9+fKFD/hxdf/WZvX24+Zvj6Rv18Wba+J3G+L3f0Rv/LLx+4PcRqK1nmiv8/JGSrmf7m+mlDVeWd+X1xKd1e0GiKalGC/slaRksbOVEGKp+t87+XPeUw7CtLKyoiq9Zr0q9zSlr0KoapomtjWxA6tdWH0KQTzXimcaibIczzS2kpWdwtNYthEIX5ubDfx5915LalTFSq+v9Y/GGoRQaGv5Biw0Yb6pgYc7lUd8Y3O3tZWsPfxH2IyVVuO1v/jO3VgjlpEOBKlQae0WOgeV7mGtmxe7qYp8UFeeAR5kyttZ6Um6tp2T9sV+pqrtCVpSUPcEdfdQS1bg7qGWqUO+JUfLSroJ6xA2/wdEU7XtXDNR7EZ5wR8Mvz8XvBq5Ggxy/mB4IbS4EApHFiOh0JJvgbsS5HzBcCi0NBfgfPPhj/wc2OHzfLZUleS9bMmKWwAAI2YDaUD0ACAAGBGAGRCTHtEjAACg1yEkagT/VUcRxFpVrCi9XrZwaLc7UZPRbMasZuy4a2jK6x0cZIYZ2knbHJTNSREs47CRJIFTuAWjHWeAokJNO9KYyZefGx6iMeNJ2jzNkq+5menj9qmxwQunnGdPDo2zds+oy8PaXQ6SGWMIzERYTwBZUVVVhRAWSsLkKGvHBsbslmMU5rRiNI4RFpywkpSNpigHRTMWK21lbIPTDDqAYqgB9Pras+dsvkxSNKrTDRsHgM6IohhhxRmXy+OemHn93BuvnJl8dYYdGR+aGD/mnUAQo95gAkJLFduqpMBETpjyvjDqYsZwC06x7vGRl9zsy2e9l968sPjhO+9efnv50y897udPn35x0uMxoxYCx0GhBYuSJsrwSbrsZF1HDhE9MJIAAQMGHeWwvTVz8eY3n391benSxfOjNHF+ymMnMZMenGAIkCi298vtnNiN88XZK37/nM8XmJ/1zfvmuUhk+ePr13+49dPtO/d+/vXOF1/feO8D7rOlT0JcZGFxOcAt/wsUJLXuetb4mgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a288e64739fc8bed672990123b6fa912/e4706/Webcam-Motion-Detector_05.avif 230w", "/en/static/a288e64739fc8bed672990123b6fa912/d1af7/Webcam-Motion-Detector_05.avif 460w", "/en/static/a288e64739fc8bed672990123b6fa912/7f308/Webcam-Motion-Detector_05.avif 920w", "/en/static/a288e64739fc8bed672990123b6fa912/b019a/Webcam-Motion-Detector_05.avif 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a288e64739fc8bed672990123b6fa912/a0b58/Webcam-Motion-Detector_05.webp 230w", "/en/static/a288e64739fc8bed672990123b6fa912/bc10c/Webcam-Motion-Detector_05.webp 460w", "/en/static/a288e64739fc8bed672990123b6fa912/966d8/Webcam-Motion-Detector_05.webp 920w", "/en/static/a288e64739fc8bed672990123b6fa912/b2d4b/Webcam-Motion-Detector_05.webp 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a288e64739fc8bed672990123b6fa912/81c8e/Webcam-Motion-Detector_05.png 230w", "/en/static/a288e64739fc8bed672990123b6fa912/08a84/Webcam-Motion-Detector_05.png 460w", "/en/static/a288e64739fc8bed672990123b6fa912/c0255/Webcam-Motion-Detector_05.png 920w", "/en/static/a288e64739fc8bed672990123b6fa912/4ad3a/Webcam-Motion-Detector_05.png 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a288e64739fc8bed672990123b6fa912/c0255/Webcam-Motion-Detector_05.png",
              "alt": "Webcam Motion Detector",
              "title": "Webcam Motion Detector",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the motion detection sensitivity by adjusting the numbers inside the detection grid.`}</p>
    <h3 {...{
      "id": "alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alerts",
        "aria-label": "alerts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alerts`}</h3>
    <p>{`When an alarm condition is detected, the program can sound an audible alarm, or send you an email with a photo. Automatically capture photos, or record video in compressed files. The Alerts section allows you to define right action for the trigger event. Click Save to finish the camera installation and start using the Webcam Motion Detector.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efb0665fe4bac563a61232732fcdabd4/4dba2/Webcam-Motion-Detector_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADXUlEQVQozx2QW0xbZQCA//b0ckrPrac9p+0KwzooowMk0U23ydzDXkx88mEPriwVuThsMiqlXUa9PGj0wcQ3k6kxIW6Zxmhi2Fyizo3EiWG4OWgLrJS2lAOn7Sk99H49v6nJ9/I9fC8fmLsX/eq+8OVCZm7x4KfH+W8e5L5dKv4ert1ZK99aLcw/yf0SKN4JlW+HqvPByu216h+R+r3Nyt2N4m8BESyF+OV1PhDPLa0Jy5vZRyHu7q+Lyw+Di3+tLD8Kb2frYa4Q2BKCsey/kYONvUq2DLMVKFagWIMgITb4PIxmq3+Gd6MivDb3A6tVWc0GrQo5+8q5Gw+/X4pvr0aEmFC69Te3EMqlipA7gFwOcjkJRNOVLT4fSRYC28Jmqv7F1zcQAE4M9vXYuk4NDW3vF9Z3c8F4JpYu3/+He7Au8rlGIlvfEVuAhCjFhUpcqIV2SusCvDm/8MyRo1Nuz7jjgtP5ZgNCrlCPpKrJEnwcK4dSUh5CUYI52AKsxMTgTmEjWVnZEt6dmR1zTU+MT15wvOUYHhmbmPR4/b4rfo/P7/b63d7Zy56rXt97016/e+bq1MwseBrbi3Kp/ULtaWyPaGuTAfAsiR7CVFqlXI3INAigNAimVmhUckQGAAAUqkIViAzIW5ISMul0ul6rxnaSlI5Wq5RajUaLqk16uudYn9FsNugohqZ0JEmTOKOnGD2NYwSJkxhGgaYEJakJIYwm+A4jS2iUZkzdw2Knu9njNlNvp+llm+Gs3dTdzhyxML0drJUhaBOto0kMJUC90Ww2W3FiN3m0w2zC1f1m3MZoOw24Rac1UqSRplhaR+v0RoY1MEYtipn6WdKEa1Vq0GhKkiRBCOMJnsQIGQCUUgnkCjmiVqk0OEnrWdPAMfuZ5wcOdQ0yh22Eju140YZShAJBwX5J2i81Cw24EU9123vbGX0frtFg+s7Dlues7AvH+84MnfS7HOfPnb7s+/DV1143m9tPnnqJIHQo2gb4ItzNNzM1uBrlDSYWUSpaG5WkXIGgSoCTbYMD/R/5XO9Pu8YunrdbLV0W8wm79f/tCNjki1vJQiJTeRLmhkfG3nA4nc4R5+g7F0cnxifedk1NffzJp9e/+/H6zZ8/+/zasHPyA8+VGff06CXXpUnXfxOlnVxXvCwDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb0665fe4bac563a61232732fcdabd4/e4706/Webcam-Motion-Detector_06.avif 230w", "/en/static/efb0665fe4bac563a61232732fcdabd4/d1af7/Webcam-Motion-Detector_06.avif 460w", "/en/static/efb0665fe4bac563a61232732fcdabd4/7f308/Webcam-Motion-Detector_06.avif 920w", "/en/static/efb0665fe4bac563a61232732fcdabd4/3c69e/Webcam-Motion-Detector_06.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efb0665fe4bac563a61232732fcdabd4/a0b58/Webcam-Motion-Detector_06.webp 230w", "/en/static/efb0665fe4bac563a61232732fcdabd4/bc10c/Webcam-Motion-Detector_06.webp 460w", "/en/static/efb0665fe4bac563a61232732fcdabd4/966d8/Webcam-Motion-Detector_06.webp 920w", "/en/static/efb0665fe4bac563a61232732fcdabd4/ee998/Webcam-Motion-Detector_06.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb0665fe4bac563a61232732fcdabd4/81c8e/Webcam-Motion-Detector_06.png 230w", "/en/static/efb0665fe4bac563a61232732fcdabd4/08a84/Webcam-Motion-Detector_06.png 460w", "/en/static/efb0665fe4bac563a61232732fcdabd4/c0255/Webcam-Motion-Detector_06.png 920w", "/en/static/efb0665fe4bac563a61232732fcdabd4/4dba2/Webcam-Motion-Detector_06.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efb0665fe4bac563a61232732fcdabd4/c0255/Webcam-Motion-Detector_06.png",
              "alt": "Webcam Motion Detector",
              "title": "Webcam Motion Detector",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define a action response to an alarm trigger event.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      